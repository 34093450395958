import { Box, Button, Typography } from "@mui/material";
import { ModalDialog } from "components";
import { FC, useEffect, useState } from "react";

interface ModalInfoProps {
  open: boolean;
  setOpen(value: boolean): void;
}

const ModalInfo: FC<ModalInfoProps> = ({ open, setOpen }) => {
  const [button, setButton] = useState(false);

  const next = async () => {
    if (!button) return;

    setOpen(false);
    window.localStorage.setItem("modalInfo", "false");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setButton(true);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <ModalDialog open={open} setOpen={next}>
      <Box justifyContent="center" alignItems="center" textAlign="center" p={4}>
        <Typography
          fontFamily="Montserrat"
          fontWeight={600}
          fontSize="15px"
          lineHeight="18px"
        >
          <span style={{ color: "#BE1717" }}>atenção!</span>
          <br />
          <br />a tabacaria está mais vip do que nunca e o acesso ao app agora é
          <span style={{ color: "#BE1717" }}> exclusivo por indicação!</span>
          <br />
          <br />
          você como um{" "}
          <span style={{ color: "#BE1717" }}>cliente especial</span> já pode
          indicar seus amigos, acesse o menu no cantinho superior e clique em
          indique um amigo para permitir que outros ingressem no clube
          tabacaria!
        </Typography>
        <Box mt={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            onClick={next}
            disabled={!button}
            style={{ opacity: button ? 1 : 0 }}
          >
            entendi!
          </Button>
        </Box>
      </Box>
    </ModalDialog>
  );
};
export default ModalInfo;
