import Grid from "@mui/material/Grid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { routes } from "routing";
import {
  RootState,
  authActions,
  menuActions,
  ordersActions,
  shoppingCartActions,
} from "state";
import app from "../../../package.json";
import { color_theme } from "../../color-theme";
import ModalConfirm from "./ModalConfirm";
import { images, texts } from "./constants";
import "./styles.css";
import { useProducts } from "hooks";
import { storeConfigActions } from "state/actions";

const Menu = () => {
  const { storeIsOpen } = useProducts();
  const { userRoutes, guestRoutes } = routes;
  const isAuthenticated = useSelector((state: RootState) => state.auth.token);
  const { userName, finished_orders_quantity, min_order_to_invite } =
    useSelector((state: RootState) => ({
      userName: state.user?.name,
      finished_orders_quantity: state.user?.finished_orders_quantity,
      min_order_to_invite: state.storeConfig.min_order_to_invite,
    }));

  const activeRoutesLinks = (isAuthenticated ? userRoutes : guestRoutes).filter(
    (route) => route.label
  );

  const visible = useSelector((state: RootState) => state.menu.open);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    dispatch(menuActions.setOpen(false));
  };

  const handleOpen = () => {
    dispatch(menuActions.setOpen(true));
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    handleClose();
    path === location.pathname && window.location.reload();
  };

  const sacContact = `${texts.whatsappUrl}${encodeURI(texts.whatsappMessage)}`;

  const logout = () => {
    handleClose();
    navigate("/");
    dispatch(authActions.logout());
    dispatch(ordersActions.resetOrders());
    dispatch(shoppingCartActions.cleanShoppingCart());
  };

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const transitionRef = useRef(null);

  const { acessoApp } = useSelector((state: RootState) => state.products);

  const inviteText = useMemo(() => {
    if (typeof min_order_to_invite === "number") {
      const missing = (finished_orders_quantity || 0) - min_order_to_invite;

      if (missing < 0) {
        return texts.missingOrders.replace("{NUM}", String(Math.abs(missing)));
      }
    }

    return texts.invite;
  }, [finished_orders_quantity, min_order_to_invite]);

  const handleInvite = () => {
    if (typeof min_order_to_invite === "number") {
      const missing = (finished_orders_quantity || 0) - min_order_to_invite;

      if (missing < 0) {
        return;
      }
    }

    navigate("/userinvite");
  };

  useEffect(() => {
    const get = async () => {
      const res = await storeIsOpen();
      dispatch(storeConfigActions.setStoreConfig(res));
    };

    void get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CSSTransition
      in={visible}
      nodeRef={transitionRef}
      timeout={500}
      classNames="menu"
      unmountOnExit
      onEnter={handleOpen}
      onExited={handleClose}
    >
      <Grid
        ref={transitionRef}
        container
        direction="column"
        alignItems="flex-start"
        height="100%"
        overflow="auto"
        sx={{
          flexWrap: "nowrap",
          position: "absolute",
          backgroundColor: color_theme.background.black.main,
          color: color_theme.background.white.main,
          fontSize: 22,
          zIndex: 1302,
          fontFamily: "Courier Prime",
          fontWeight: 700,
        }}
        // {...handlers}
      >
        <Grid item container justifyContent="flex-end" onClick={handleClose}>
          <img
            style={{
              height: 32,
              width: 32,
              margin: "40px 24px",
              cursor: "pointer",
            }}
            src={images.icon_close}
            alt="insta"
          />
        </Grid>

        {isAuthenticated && (
          <Grid
            item
            paddingLeft={"32px"}
            marginBottom={"37px"}
            onClick={handleClose}
          >
            <p>{userName}</p>
            <span
              onClick={handleInvite}
              style={{
                fontFamily: "Courier Prime",
                fontSize: "14px",
                fontWeight: 600,
                color: "#33FF00",
                border: "2px solid #33FF00",
                borderRadius: "10px",
                padding: "8px 20px",
                cursor: "pointer",
              }}
            >
              {inviteText}
            </span>
          </Grid>
        )}

        {/* Caso nao esteja logado */}

        {activeRoutesLinks.map((route: any) => (
          <Grid
            item
            key={route.path}
            onClick={() => handleNavigate(route.path)}
            sx={{
              cursor: "pointer",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              width: "100%",
              padding: "32px",
              borderTop: "1px solid #161616",
              margin: "0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ width: "24px", height: "24px", marginRight: "16px" }}
                src={images[route.icon as keyof typeof images]}
                alt="insta"
              />
              {route.label}
            </div>
            <img
              style={{ width: "24px", height: "24px" }}
              src={images.icon_right}
              alt="arrow-right"
            />
          </Grid>
        ))}

        <Grid
          item
          sx={{
            cursor: "pointer",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            width: "100%",
            border: "1px solid #161616",
            margin: "0 0 40px 0",
          }}
        >
          <a
            href={sacContact}
            target="_blank"
            rel="noreferrer"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              width: "100%",
              padding: "32px",
              margin: "0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ width: "24px", height: "24px", marginRight: "16px" }}
                src={images.icon_message}
                alt="insta"
              />
              {texts.sac}
            </div>
            <img
              style={{ width: "24px", height: "24px" }}
              src={images.icon_right}
              alt="arrow-right"
            />
          </a>
        </Grid>

        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "auto",
            color: color_theme.text.black.faded,
            mb: 2,
          }}
        >
          <Grid
            sx={{
              height: "54px",
              mb: "35px",
            }}
          >
            <a
              href="https://www.instagram.com/tabacariadelivery.cwb?igsh=MWF4a25ramVqZjY1bQ%3D%3D&utm_source=qr"
              target="_blank"
              rel="noreferrer"
              style={{ margin: "0 24px" }}
            >
              <img
                className="link-button"
                style={{ width: "40px", height: "40px" }}
                src={images.insta}
                alt="insta"
              />
            </a>
          </Grid>
          {acessoApp && (
            <>
              <ModalConfirm
                open={openConfirmationModal}
                setOpen={setOpenConfirmationModal}
              />
              <div
                onClick={() => setOpenConfirmationModal(true)}
                style={{
                  cursor: "pointer",
                  color: color_theme.error.main,
                  marginBottom: 40,
                }}
              >
                solicitar exclusão da conta
              </div>{" "}
            </>
          )}
          {isAuthenticated && (
            <div
              onClick={logout}
              style={{ cursor: "pointer", marginBottom: 6 }}
            >
              {texts.logout}
            </div>
          )}
          <div
            style={{
              fontSize: 12,
              fontFamily: "Montserrat",
            }}
          >
            v{app.version}-{process.env.REACT_APP_ENVIRONMENT}
          </div>
        </Grid>
      </Grid>
    </CSSTransition>
  );
};

export default Menu;
