import { HeaderBase } from "components";
import { images } from "./constants";

const Header = () => {
  return (
    <HeaderBase center>
      <img
        style={{ height: "50px" }}
        src={images.logo}
        alt="TABACARIA DELIVERY"
      ></img>
    </HeaderBase>
  );
};

export default Header;
