const PR = [
  "Curitiba",
  "Araucária",
  "Almirante Tamandaré",
  "Colombo",
  "Piraquara",
  "Pinhais",
  "São José dos Pinhais",
  "Campo Largo",
  "Campina Grande do Sul",
  "Fazenda Rio Grande",
  "Quatro Barras",
  "Rio Branco do Sul",
  "Tijucas do Sul",
];

// const SP = ["São Paulo"];

const ACTIVE_CITY = [...PR];

export const isInServiceArea = (
  region: string,
  options: { strict?: boolean } = { strict: false }
) => {
  // This is case-sensitive
  for (const city of ACTIVE_CITY) {
    if (region === city) return true;
    if (!options.strict) {
      if (region.includes(city)) return true;
    }
  }

  return false;
};
